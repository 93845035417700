<template lang="pug">
  td.col--reserved(:style="{backgroundColor: reservedColor}" @click="$emit('click')" :class="{reversed: reversed, small: small}")
    .d-flex.flex-column
      p(v-if="item.exam.location" class="city") {{ item.exam.location }}
      p(v-if="!item.exam.location && !item.exam.examDate") -
      p(v-if="item.exam.examDate" class="date") {{ item.exam.examDate }}
</template>

<script>
import { convertToDefaultDateFormat } from '@/util';
import { RESERVED_COLORS } from '../../../results/core/results-const'

export default {
  name: 'CandidateReservedInfo',

  props: {
    item: { type: Object, required: true },
    courseDate: { type: String, default: '' },
    reversed: { type: Boolean, default: false },
    small: { type: Boolean, default: false }
  },

  data: () => ({
    RESERVED_COLORS
  }),

  computed: {
    hasCourseDate() {
      return !!this.courseDate
    },

    reservedColor: function () {
      if (this.item.isMijnReservation) return RESERVED_COLORS.IS_MIJN_RESERVATION
      if (this.item.exchange_count === 3) return RESERVED_COLORS.WAS_EXCHANGED
      if ((this.item.exam.date !== convertToDefaultDateFormat(this.courseDate)) && this.hasCourseDate) return RESERVED_COLORS.DIFFERENT_EXAM_DATE
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.col--reserved {
  min-width: 105px;
  max-width: 105px;

  &.reversed {
    .city { order: 1 }
    .date { order: 0 }
  }

  &.small {
    p {
      @include media("<=tablet") {
        font-size: 11px;
      }
    }

    @include media("<=tablet") {
      min-width: 70px;
      max-width: 70px;
      width: 70px;
    }
  }

  p {
    margin: 0;
    text-align: center;
  }

  .city {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    order: 0;
  }

  .date {
    order: 1;
  }
}
</style>
